import { BASE_APP_URL } from "settings";

export const firebaseConfig = {
  apiKey: "AIzaSyBQPMF2DVgokqQPlaA5LoFe2kZf6U8AenU",
  authDomain: "codex-sheet-2022.firebaseapp.com",
  projectId: "codex-sheet-2022",
  storageBucket: "codex-sheet-2022.appspot.com",
  messagingSenderId: "439438002353",
  appId: "1:439438002353:web:88c300dc4bdceb6768fa90",
};

export const signInWithEmailActionCodeSettings = {
  url: `${BASE_APP_URL}/`,
  // This must be true.
  handleCodeInApp: true,
};

export const TIME_TO_RELOAD_TOKEN = 1000 * 60 * 55
