import { useState } from "react";
import { useTranslation } from "react-i18next";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import Cookies from "js-cookie";
import { I18N_COOKIE_NAME } from "settings/i18n";
import i18next from "i18next";
import CdxChangeLanguage from "components/cdx/CdxChangeLanguage";

const AuthFooter = () => {
  const { t } = useTranslation();

  const [openChangeLanguage, setOpenChangeLanguage] = useState(false);

  const handleClose = () => {
    setOpenChangeLanguage(false);
  };

  // const changeLanguage = () => {
  //   const curLng = Cookies.get(I18N_COOKIE_NAME);
  //   if (curLng === "th") {
  //     i18next.changeLanguage("en");
  //     Cookies.set(I18N_COOKIE_NAME, "en");
  //   } else {
  //     i18next.changeLanguage("th");
  //     Cookies.set(I18N_COOKIE_NAME, "th");
  //   }
  // };

  return (
    <SuiBox sx={{ width: "30rem" }} px={2}>
      <SuiTypography
        variant="caption"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setOpenChangeLanguage(true);
        }}
      >
        {t("Change language")}
      </SuiTypography>
      <CdxChangeLanguage open={openChangeLanguage} handleClose={handleClose} />
    </SuiBox>
  );
};

export default AuthFooter;
