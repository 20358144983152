import React, { useEffect } from "react";
import { Provider } from "react-redux";

import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import App from "./App";

import store from "store";

import "i18n";

import { rootElement } from "settings";
import { GA_TRACKING_ID } from "settings/ga";

const script = document.createElement("script");
script.async = true;
script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
document.head.appendChild(script);

const root = createRoot(rootElement);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);
