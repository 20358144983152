import i18next from "i18next";

import Cookies from "js-cookie";

import { I18N_COOKIE_NAME } from "settings/i18n";

const changeLanguage = (lng = null) => {
  if (lng) {
    i18next.changeLanguage(lng);
    Cookies.set(I18N_COOKIE_NAME, lng);
  }
};

export default changeLanguage;
