import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

const NoFileInFolder = () => {
  const { t } = useTranslation();
  return (
    <SuiBox
      py={20}
      textAlign="center"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <SuiBox p={2}>
        <SuiTypography variant="h1">
          <Icon fontSize="inherit">thumb_down_alt</Icon>
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <SuiTypography variant="header1" color="secondary">
          {t("No folder or file in a given folder")}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export default NoFileInFolder;
