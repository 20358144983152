import Icon from "@mui/material/Icon";
import CdxCard from "components/cdx/CdxCard";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import ContactSupportForm from "./contact-support-form";

const ContactSupport = () => {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);

  return (
    <SuiBox mt={3}>
      <CdxCard p={3}>
        <SuiTypography variant="h6">{t("Contact support")}</SuiTypography>
        {success ? (
          <SuiBox
            mt={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <SuiBox p={2}>
              <SuiTypography variant="h1">
                <Icon fontSize="inherit">send</Icon>
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="header1" color="secondary">
                {t("Your message is successfully send to support team")}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : (
          <ContactSupportForm setSuccess={setSuccess} />
        )}
      </CdxCard>
    </SuiBox>
  );
};

export default ContactSupport;
