import { Routes, Route, Navigate, useLocation } from "react-router-dom";

const RoutesRender = ({ routes }) => {
  const { pathname } = useLocation();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.path && route.element) {
        return (
          <Route
            exact
            path={route.path}
            element={route.element}
            key={route.key}
          />
        );
      } else if (route.path && route.signInRequired) {
        return (
          <Route
            exact
            path={route.path}
            element={
              <Navigate
                to={`${route.signInPage || "/sign-in"}?redirect_to=${pathname}`}
              />
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  return <Routes>{getRoutes(routes)}</Routes>;
};

export default RoutesRender;
