import { auth } from "./config";

import { signOut as fbSignOut } from "firebase/auth";

import { LSK_CDX_IS_EMAIL_SIGNIN } from "settings/localStorageKeys";

const signOut = () => {
  // const dispatch = store.dispatch;

  fbSignOut(auth)
    .then(() => {
      // Sign-out successful.
      // dispatch(dispatchSignOut());
      window.localStorage.removeItem(LSK_CDX_IS_EMAIL_SIGNIN);
    })
    .catch((error) => {
      // An error happened.
    });

  return <></>;
};

export default signOut;
