import SuiBox from "components/sui/SuiBox";
import SuiTagInput from "components/sui/SuiTagInput";
import SuiTypography from "components/sui/SuiTypography";
import CdxBadgeDot from "components/cdx/CdxBadgeDot";

const CdxTagInput = ({
  label,
  mb,
  error,
  success,
  message,
  disabled,
  ...inputProps
}) => {
  const color = () => {
    if (error) {
      return "error";
    } else if (success) {
      return "success";
    } else {
      return "inherit";
    }
  };

  const renderInputOnDisabled = () => {
    return inputProps.tags.map((e) => {
      return <CdxBadgeDot badgeContent={e} key={Math.random()} />;
    });
  };

  return (
    <>
      {label && (
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox mb={mb ? mb : 0}>
        {!disabled && (
          <SuiTagInput {...inputProps} error={error} success={success} />
        )}
        {disabled && (
          <SuiBox sx={{ display: "flex" }}>{renderInputOnDisabled()}</SuiBox>
        )}
        {message && (
          <SuiBox px={1.5} sx={{ lineHeight: 1 }}>
            <SuiTypography color={color()} variant="caption">
              {message}
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
    </>
  );
};

export default CdxTagInput;
