import { Grid } from "@mui/material";
import CdxInput from "components/cdx/CdxInput";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import CdxButton from "components/cdx/CdxButton";
import { connect } from "react-redux";
import { getWalletBalance } from "actions";
import { codexApiCatchError } from "actions/snackBar";
import CodexAPI from "app/api/codexapi";

const RedeemFreeCode = ({ getWalletBalance, codexApiCatchError }) => {
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState("");

  const submit = async () => {
    try {
      await CodexAPI().post("/user/wallet/purchase/", {
        fiat_amount: 0,
        currency: "THB",
        purchase_credit: 0,
        promo_code: promoCode,
      });
      setPromoCode("");
      getWalletBalance();
    } catch (error) {
      codexApiCatchError(error);
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <CdxInput
            placeholder={t("Promotion code")}
            value={promoCode}
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <CdxButton
            color="info"
            variant="gradient"
            onClick={submit}
            disabled={promoCode.length < 1}
          >
            {t("Redeem code")}
          </CdxButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { getWalletBalance, codexApiCatchError };

export default connect(mapStateToProps, mapDispatchToProps)(RedeemFreeCode);
