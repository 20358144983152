import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import Grid from "@mui/material/Grid";
import { useNavigate, NavLink } from "react-router-dom";
import { BASE_PATH } from "settings";
import { useRef, useState } from "react";

import FileMenu from "./FileMenu";

const CdxFileItem = ({ file }) => {
  let navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleContextMenu = (e) => {
    e.preventDefault();
    handleOpenMenu(e);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xxl={2}>
      <SuiBox
        display="flex"
        alignItems="center"
        bgColor="white"
        borderRadius="md"
        shadow="xl"
        sx={{ userSelect: "none", cursor: "pointer" }}
        onContextMenu={handleContextMenu}
        onDoubleClick={() => {
          navigate(`${BASE_PATH}/sheet?sheet_uuid=${file.sheet_uuid}`);
        }}
      >
        <SuiTypography
          component="div"
          textGradient
          color={["success", "primary", "secondary", "dark", "error"].at(
            file.status === 0 ? 0 : file.status || 3
          )}
        >
          <SuiBox
            p={2}
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            {file.status === 0 && (
              <Icon fontSize="default" m={1}>
                insert_drive_file
              </Icon>
            )}
            {[1, 2].includes(file.status) && (
              <Icon fontSize="default" m={1}>
                functions
              </Icon>
            )}
            {file.status === 3 && (
              <Icon fontSize="default" m={1}>
                archive
              </Icon>
            )}
            {file.status === 4 && (
              <Icon fontSize="default" m={1}>
                error
              </Icon>
            )}
          </SuiBox>
        </SuiTypography>

        <SuiBox pr={2} py={2} lineHeight={1}>
          <SuiTypography display="block" variant="button" fontWeight="medium">
            {file.sheet_name}
          </SuiTypography>
          <FileMenu
            openMenu={openMenu}
            handleCloseMenu={handleCloseMenu}
            file={file}
          />
        </SuiBox>
      </SuiBox>
    </Grid>
  );
};

export default CdxFileItem;
