import { Grid } from "@mui/material";

import CdxCard from "components/cdx/CdxCard";

import AuthFooter from "./AuthFooter";

import useDetectBreakpoint from "app/hooks/useDetectBreakPoint";


const AuthLayout = ({ children }) => {
  const bp = useDetectBreakpoint();

  return (
    <>
      {bp === "xs" ? (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item width="100%">
            {children}
          </Grid>
          <Grid item width="100%" pb={2}>
            <AuthFooter />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item width="30rem">
            <CdxCard>
              {children}
            </CdxCard>
          </Grid>
          <Grid item width="30rem">
            <AuthFooter />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AuthLayout;
