import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Item from "./Item";
import Divider from "@mui/material/Divider";
import { BASE_PATH } from "settings";

import { useTranslation } from "react-i18next";

import signOut from "app/fb/signOut";

import CdxChangeLanguage from "../CdxChangeLanguage";

const UserMenu = ({ openMenu, handleCloseMenu, name }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [openChangeLanguage, setOpenChangeLanguage] = useState(false);

  const handleClose = () => {
    setOpenChangeLanguage(false);
    handleCloseMenu();
  };

  return (
    <>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <Item title={name} onClick={handleCloseMenu} />
        <Divider sx={{ my: 1 }} />
        <Item
          title={t("Change language")}
          onClick={() => {
            setOpenChangeLanguage(true);
          }}
        />
        <NavLink to={`${BASE_PATH}/sign-out`}>
          <Item title={t("Sign out")} onClick={signOut} />
        </NavLink>
      </Menu>
      <CdxChangeLanguage open={openChangeLanguage} handleClose={handleClose} />
    </>
  );
};

export default UserMenu;
