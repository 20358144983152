import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthLayout from "components/layouts/AuthLayout";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import SignUpRequestForm from "./requestForm";

const SignUp = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <SuiBox
        p={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        width="100%"
      >
        <SuiBox>
          <SuiTypography variant="h5">{t("Sign up")}</SuiTypography>
        </SuiBox>
        <SignUpRequestForm />
      </SuiBox>
    </AuthLayout>
  );
};

export default SignUp;
