import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SuiBox from "components/sui/SuiBox";
import MoveForm from "./form";

const MoveModal = ({ open, handleClose, file }) => {
  const { t } = useTranslation();
  const [name, setName] = useState();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={handleClose}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <SuiBox p={2}>
        <DialogTitle>{t("Move sheet")}</DialogTitle>
        <DialogContent>
          <MoveForm handleClose={handleClose} file={file} />
        </DialogContent>
      </SuiBox>
    </Dialog>
  );
};

export default MoveModal;
