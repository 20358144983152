import { useEffect } from "react";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";

import { connect } from "react-redux";

import { GA_TRACKING_ID } from "settings/ga";
import gtag from "app/ga";

import RoutesRender from "app/utils/RoutesRender";
import { normalRoutes, nonAuthRoutes } from "routes";
import AuthApp from "AuthApp";

import FullPageLoading from "components/layouts/FullPageLoading";

const AppRoute = ({ isSignIn }) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryStr = searchParams.toString();
    if (queryStr) {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
        queries: queryStr,
      });
    } else {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
      });
    }
  }, [pathname]);

  if (isSignIn === null) {
    return (
      <Routes>
        <Route path="*" element={<FullPageLoading />} />
      </Routes>
    );
  }
  if (!isSignIn) {
    return <RoutesRender routes={[...normalRoutes, ...nonAuthRoutes]} />;
  } else {
    return <AuthApp />;
  }
};

const mapStateToProps = (state) => {
  return { isSignIn: state.app.isSignIn };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
