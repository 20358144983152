import SuiBox from "components/sui/SuiBox";
import SuiInput from "components/sui/SuiInput";
import SuiTypography from "components/sui/SuiTypography";

const CdxInput = ({label, mb, error, success, message,  ...inputProps }) => {
  const color = () => {
    if (error) {
      return "error";
    } else if (success) {
      return "success";
    } else {
      return "inherit";
    }
  };

  return (
    <>
      {label && (
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox mb={mb ? mb : 0}>
        <SuiInput {...inputProps} error={error} success={success} />
        {message && (
          <SuiBox px={1.5} sx={{ lineHeight: 1 }}>
            <SuiTypography color={color()} variant="caption">
              {message}
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
    </>
  );
};

export default CdxInput;
