import hash from "./hash";
import { LESS_SECURE_KEY } from "settings";

export default function hashkey(key) {
  let key2hash = key;
  if (!key2hash) {
    key2hash = LESS_SECURE_KEY;
  }
  return hash(key2hash);
}
