import { useEffect, useRef } from "react";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";

// Custom styles for the SuiDropzone
import CdxDropzoneRoot from "components/cdx/CdxDropzone/CdxDropzoneRoot";

function CdxDropzoneUploadMultiple({ setFile }) {
  const dropzoneRef = useRef();

  const options = {
    addRemoveLinks: true,
    maxFiles: 10,
    autoQueue: false,
    init: function () {
      this.on("addedfile", (file) => {
        const files = this.files;
        setFile([...this.files]);
        setTimeout(() => {
          this.removeFile(file);
        }, 100);
      });
    },
  };

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, []);

  return (
    <CdxDropzoneRoot
      component="div"
      action="/"
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SuiBox className="fallback">
        <SuiBox component="input" name="file" type="file" />
      </SuiBox>
    </CdxDropzoneRoot>
  );
}

export default CdxDropzoneUploadMultiple;
