import {
  MY_FOLDER_HASH,
  SHEET_HASH,
  WALLET_DETAIL_HASH,
  SIGN_OUT,
} from "actions/types";

const hashDictionaryDefault = {
  MY_FOLDER_HASH: "",
  SHEET_HASH: {},
  WALLET_DETAIL_HASH: "",
};

const clearHashMap = {};

const hashDictionaryReducer = (
  hashDictionary = hashDictionaryDefault,
  action
) => {
  if (Object.keys(hashDictionaryDefault).includes(action.type)) {
    if (typeof hashDictionary[action.type] === "object") {
      return {
        ...hashDictionary,
        [action.type]: {
          ...hashDictionary[action.type],
          ...action.payload,
        },
      };
    } else {
      return { ...hashDictionary, [action.type]: action.payload };
    }
  }

  if (Object.keys(clearHashMap).includes(action.type)) {
    return {
      ...hashDictionary,
      [clearHashMap[action.type]]: action.payload.hashDictionary,
    };
  }

  if (action.type === SIGN_OUT) {
    return {
      ...hashDictionaryDefault,
    };
  }

  return hashDictionary;
};

export default hashDictionaryReducer;
