import { useEffect, useState } from "react";
import CdxCollapseCard from "components/cdx/CdxCollapseCard";
import { useTranslation } from "react-i18next";
import CdxDataTable from "components/cdx/CdxDataTable";
import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const InvoiceTable = ({ invoice, folder }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (invoice) {
      invoice = invoice.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1;
        } else if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      });
      setRows(
        invoice.map((e) => {
          const file = folder.find((f) => f.sheet_uuid === e.sheet_uuid) || {};
          return {
            ...e,
            sheet_name: file.sheet_name || e.sheet_uuid,
            sheet_path: file.sheet_path || "/",
          };
        })
      );
    } else {
      setRows([]);
    }
  }, [invoice, folder]);

  return (
    <CdxCollapseCard p={3} title={t("Credit usage (30 days history)")}>
      <CdxDataTable
        entriesPerPage={{
          defaultValue: 10,
          entries: [
            { value: 5, label: 5 },
            { value: 10, label: 10 },
            { value: 15, label: 15 },
            { value: 20, label: 20 },
            { value: 25, label: 25 },
            { value: -1, label: t("Show all") },
          ],
        }}
        canSearch
        showSelectEntriesPerPage={true}
        table={{
          columns: [
            {
              Header: t("Date time"),
              accessor: "timestamp",
              Cell: ({ value }) => {
                return isoTimeToLocaleTimeString(value);
              },
            },
            {
              Header: t("Sheet name"),
              accessor: "sheet_name",
            },
            {
              Header: t("Sheet path"),
              accessor: "sheet_path",
            },
            {
              Header: t("Purchased credit"),
              accessor: "purchase_credit",
            },
            {
              Header: t("Free credit"),
              accessor: "free_credit",
            },
          ],
          rows: rows,
        }}
      />
    </CdxCollapseCard>
  );
};

export default InvoiceTable;
