import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getWalletBalance, getWalletDetail, getMyFolder } from "actions";
import { useEffect } from "react";
import CdxTransparentCollapseCard from "components/cdx/CdxTransparentCollapseCard";
import CdxDefaultCounterCard from "components/cdx/CdxCounterCards/DefaultCounterCard";

import { Grid } from "@mui/material";
import CdxCollapseCard from "components/cdx/CdxCollapseCard";
import RedeemFreeCode from "./redeem-free-code";
import InvoiceTable from "./InvoiceTable";
import ReceiptTable from "./ReceiptTable";

const Wallet = ({
  wallet,
  wallet_detail,
  folder,
  getWalletBalance,
  getWalletDetail,
  getMyFolder,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getWalletBalance();
    getMyFolder();
    getWalletDetail();
  }, []);

  return (
    <SuiBox mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CdxCollapseCard title={t("Redeem promo code")} p={3}>
            <RedeemFreeCode />
            <SuiBox mt={1.5}>
              <SuiTypography variant="caption">
                {t("Please contact us if you would like to purchase a credit.")}
              </SuiTypography>
            </SuiBox>
          </CdxCollapseCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CdxTransparentCollapseCard p={3} title={t("Current balance")}>
            <Grid container spacing={2}>
              <Grid item xs>
                <CdxDefaultCounterCard
                  title={t("Purchased credit")}
                  color="primary"
                  count={wallet.purchase_credit || 0}
                />
              </Grid>
              <Grid item xs>
                <CdxDefaultCounterCard
                  title={t("Free credit")}
                  color="primary"
                  count={wallet.free_credit || 0}
                />
              </Grid>
            </Grid>
          </CdxTransparentCollapseCard>
        </Grid>
        <Grid item xs={12}>
          <InvoiceTable invoice={wallet_detail.invoice || []} folder={folder} />
        </Grid>
        <Grid item xs={12}>
          <ReceiptTable receipt={wallet_detail.receipt || []} />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    wallet: state.user.wallet,
    wallet_detail: state.user.wallet_detail,
    folder: state.folder,
  };
};

const mapDispatchToProps = { getWalletBalance, getWalletDetail, getMyFolder };

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
