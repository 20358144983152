import { GET_MY_FOLDER, MY_FOLDER_HASH } from "./types";
import CodexAPI from "app/api/codexapi";

import { apiServerError } from "actions/snackBar";

export const getMyFolder = () => async (dispatch, getState) => {
  const prevHash = getState().hashDictionary[MY_FOLDER_HASH];
  try {
    let response = await CodexAPI().get("/folder/my-folder/hash/");
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/folder/my-folder/", {
        params: { hash: newHash },
      });
      dispatch({
        type: MY_FOLDER_HASH,
        payload: newHash,
      });
      dispatch({ type: GET_MY_FOLDER, payload: response.data.data });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};
