import { useEffect, useState } from "react";
import CdxCollapseCard from "components/cdx/CdxCollapseCard";
import { useTranslation } from "react-i18next";
import CdxDataTable from "components/cdx/CdxDataTable";
import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const ReceiptTable = ({ receipt }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (receipt) {
      setRows(receipt);
    } else {
      setRows([]);
    }
  }, [receipt]);

  return (
    <CdxCollapseCard p={3} title={t("Credit received (30 days history)")}>
      <CdxDataTable
        entriesPerPage={{
          defaultValue: 10,
          entries: [
            { value: 5, label: 5 },
            { value: 10, label: 10 },
            { value: 15, label: 15 },
            { value: 20, label: 20 },
            { value: 25, label: 25 },
            { value: -1, label: t("Show all") },
          ],
        }}
        canSearch
        showSelectEntriesPerPage={true}
        table={{
          columns: [
            {
              Header: t("Date time"),
              accessor: "timestamp",
              Cell: ({ value }) => {
                return isoTimeToLocaleTimeString(value);
              },
            },
            {
              Header: t("Payment"),
              accessor: "fiat_amount",
            },
            {
              Header: t("Currency"),
              accessor: "currency",
            },
            {
              Header: t("Purchased credit"),
              accessor: "purchase_credit",
            },
            {
              Header: t("Free credit"),
              accessor: "free_credit",
            },
            {
              Header: t("Promo code"),
              accessor: "promo_code",
            },
          ],
          rows: rows,
        }}
      />
    </CdxCollapseCard>
  );
};

export default ReceiptTable;
