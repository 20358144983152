import store from "store";

import { auth } from "./config";

import { reloadToken as actionReloadToken } from "actions";

import { authServerError } from "actions/snackBar";

const reloadToken = async () => {
  const dispatch = store.dispatch;
  const user = auth.currentUser;
  try {
    if (user) {
      await user.getIdToken(true);
      dispatch(actionReloadToken(user));

      setTimeout(
        reloadToken,
        user.stsTokenManager.expirationTime - Date.now() - 1000
      );
      
    }
  } catch {
    dispatch(authServerError());
  }
};

export default reloadToken;
