import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxInput from "components/cdx/CdxInput";
import CdxButton from "components/cdx/CdxButton";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";


import CodexAPI from "app/api/codexapi";
import CdxDropzoneUploadMultiple from "components/cdx/CdxDropzoneUploadMultiple";
import SuiBadgeDot from "components/cdx/CdxBadgeDot";
import _ from "lodash";

const ContactSupportForm = ({ email, setSuccess }) => {
  const { t } = useTranslation();

  const validateForm = (values) => {
    const error = {};
    if (!values.email) {
      error.email = "Please enter email";
    } else {
      const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      };
      if (!validateEmail(values.email)) {
        error.email = "Please enter a valid email";
      }
    }

    if (!values.subject) {
      error.subject = "Please enter subject";
    }
    if (!values.message) {
      error.message = "Please enter message";
    } else if (values.message.length < 10) {
      error.message = "Message must be greater than 10 characters length";
    }
    if ((values.files || []).length > 0) {
      const acceptContent = [
        "image/jpeg",
        "image/png",
        "text/csv",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (values.files.length > 5) {
        error.files = "Number of file exceed the limit (5 files)";
      } else if (_.sumBy(values.files, "size") > 10 ** 7) {
        error.files = "File size exceed the limit (10 megabytes)";
      } else if (
        values.files.filter((e) => !acceptContent.includes(e.type)).length > 0
      ) {
        error.files = "Only support jpg, jpeg, png, csv, txt and xlsx format";
      }
    }
    return error;
  };

  const onSubmit = async (values) => {
    const errorMessage = {};
    const formData = new FormData();

    formData.append("email", values.email);
    formData.append("subject", values.subject);
    formData.append("message", values.message);
    values.files.forEach((e, idx) => {
      return formData.append(`file_${idx}`, e);
    });

    try {
      await CodexAPI().post("/contact-support/", formData);
      setSuccess(true);
    } catch (error) {
      if (!error.response) {
        errorMessage[FORM_ERROR] =
          "Couldn't connect to Codex Sheet server, please check your internet connection.";
      } else {
        if ([400, 422].includes(error.response.status)) {
          errorMessage[FORM_ERROR] = "Something went wrong, please try again";
        } else {
          errorMessage[FORM_ERROR] =
            "Email server error, please try again or contact us via other channel";
        }
      }
    }
    return errorMessage;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <SuiBox
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
            mt={3}
          >
            <Field name="email" initialValue={email}>
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  placeholder={t("Email")}
                  disabled={!!email}
                  mb={2}
                  error={!!(meta.error && meta.touched) || !!meta.submitError}
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            <Field name="subject">
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  placeholder={t("Subject")}
                  mb={2}
                  error={!!(meta.error && meta.touched) || !!meta.submitError}
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            <Field name="message">
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  multiline
                  rows={5}
                  placeholder={t("Message")}
                  mb={2}
                  error={!!(meta.error && meta.touched) || !!meta.submitError}
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            <Field name="files">
              {({ input, meta }) => {
                const setFile = (newFile) => {
                  input.onChange([...input.value, ...newFile]);
                };
                return (
                  <Fragment>
                    {(input.value || []).length === 0 ? (
                      <CdxDropzoneUploadMultiple setFile={setFile} />
                    ) : (
                      <Fragment>
                        <SuiBox>
                          <SuiButton
                            color="warning"
                            variant="gradient"
                            size="small"
                            onClick={() => {
                              input.onChange([]);
                            }}
                          >
                            {t("Select file again")}
                          </SuiButton>
                        </SuiBox>
                        {meta.error ? (
                          <SuiBox mt={1}>
                            <SuiTypography variant="caption" color="error">
                              {meta.error}
                            </SuiTypography>
                          </SuiBox>
                        ) : (
                          <Fragment>
                            <SuiTypography variant="caption" fontWeight="bold">
                              {t("File list:")}
                            </SuiTypography>
                            {input.value.map((e, idx) => (
                              <SuiBadgeDot
                                key={`${e.name}-${idx}`}
                                badgeContent={e.name}
                              />
                            ))}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                );
              }}
            </Field>

            {submitError && (
              <SuiBox>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </SuiBox>
            )}

            <SuiBox
              width="100%"
              mt={submitError ? 1 : 3}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <SuiBox>
                <CdxButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  progress={submitting}
                  disabled={
                    submitting ||
                    pristine ||
                    Object.values(formState.errors).length > 0
                  }
                >
                  {t("Send")}
                </CdxButton>
              </SuiBox>
              <SuiBox>
                <SuiButton
                  variant="gradient"
                  color="error"
                  onClick={form.reset}
                >
                  {t("Clear")}
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return { email: state.user.email };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSupportForm);
