import { deploySnackbar } from "actions/app";

import { UNKNOWN_ERROR_MESSAGE } from "settings";

export const apiServerError = () => (dispatch, getState) => {
  const snackBar = {
    color: "error",
    icon: "report_problem",
    title: "Unable to connect API server",
    content: `Please check your internet connection.`,
  };
  dispatch(deploySnackbar(snackBar));
};

export const authServerError = () => (dispatch, getState) => {
  const snackBar = {
    color: "error",
    icon: "report_problem",
    title: "Unable to connect authentication server",
    content: `Please check your internet connection.`,
  };
  dispatch(deploySnackbar(snackBar));
};

export const codexApiCatchError = (error) => (dispatch, getState) => {
  if (!error.response) {
    dispatch(apiServerError());
  } else {
    const content = error.response.data.message
      ? error.response.data.message.text
      : UNKNOWN_ERROR_MESSAGE;
    const snackBar = {
      color: "error",
      icon: "report_problem",
      title: error.response.data.error || "Error",
      content: content,
    };
    dispatch(deploySnackbar(snackBar));
  }
};
