import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxInput from "components/cdx/CdxInput";
import CdxTagInput from "components/cdx/CdxTagInput";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import CodexAPI from "app/api/codexapi";

import { getMyFolder } from "actions";
import { codexApiCatchError } from "actions/snackBar";
import { useState, useEffect, Fragment } from "react";

const MoveForm = ({
  file,
  getMyFolder,
  codexApiCatchError,
  handleClose = () => {},
}) => {
  const { t } = useTranslation();

  const validateForm = (values) => {
    const error = {};
    return error;
  };

  const onSubmit = async (values) => {
    const error = {};
    const data = {
      sheet_uuid: file.sheet_uuid,
      sheet_path: values.sheet_path || "/",
    };

    try {
      await CodexAPI().post("folder/move-sheet/", data);
      getMyFolder();
    } catch (error) {
      codexApiCatchError(error);
    }
    handleClose();
    return error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      initialValues={file}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <SuiBox
            component="div"
            role="div"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Field name="sheet_path">
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  label={t("Sheet path*")}
                  mb={2}
                  error={!!(meta.error && meta.touched)}
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            {submitError && (
              <SuiBox>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </SuiBox>
            )}
            <SuiBox>
              <SuiButton
                variant="gradient"
                color="info"
                onClick={() => {
                  onSubmit(formState.values);
                }}
                disabled={
                  submitting ||
                  Object.values(formState.errors).length > 0 ||
                  pristine
                }
              >
                {t("Move")}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return { sheet_uuid: state.app.activeSheet };
};

const mapDispatchToProps = { getMyFolder, codexApiCatchError };

export default connect(mapStateToProps, mapDispatchToProps)(MoveForm);
