import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useSoftUIController } from "context";

import breakpoints from "assets/theme/base/breakpoints";

import { selectActiveSheet, deselectActiveSheet, loadSheet } from "actions";

import { BASE_PATH } from "settings";

import getSheetFunc from "app/hooks/getSheetFunc";

import { LSK_CDX_ACTIVE_SHEET } from "settings/localStorageKeys";
import decryptToObject from "app/utils/decryptToObject";
import InvalidUuid from "./static/invalid-uuid";
import PleaseSelectSheet from "./static/please-select-sheet";
import FullPageLoading from "components/layouts/FullPageLoading";

import { useTranslation } from "react-i18next";

const Sheet = ({
  uid,
  activeSheet,
  selectActiveSheet,
  deselectActiveSheet,
  sheetList,
  loadSheet,
}) => {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sheet_uuid, setSheetUuid] = useState(
    searchParams.get("sheet_uuid") || ""
  );
  const { pathname } = useLocation();
  const [tabs, setTabs] = useState([]);

  const [sheet, setSheet] = useState({});

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    setSheetUuid(searchParams.get("sheet_uuid") || "");
  }, [searchParams]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const delaySetTabValue = (value, delay = 400) => {
    const dSetTabValue = () => {
      setTabValue(value === 0 ? 1 : value - 1);
      setTabValue(value);
    };

    const timeOutId = setTimeout(dSetTabValue, delay);
    return timeOutId;
  };

  useEffect(() => {
    if (sheet_uuid) {
      selectActiveSheet(sheet_uuid);
    } else {
      let localUuid = window.localStorage.getItem(LSK_CDX_ACTIVE_SHEET);
      try {
        localUuid = decryptToObject(localUuid);
      } catch {
        localUuid = {};
      }

      if (localUuid.uid === uid && !(uid == null)) {
        selectActiveSheet(localUuid.uuid);
      }
    }
  }, [sheet_uuid]);

  useEffect(() => {
    if (activeSheet) {
      loadSheet(activeSheet);
    }
  }, [activeSheet]);

  useEffect(() => {
    if (Object.keys(sheetList).length > 0 && activeSheet) {
      const sh = sheetList[activeSheet];
      if (sh) {
        setSheet(sh);
      }
    }
  }, [sheetList]);

  const setTabsGetFunction = async (sheet) => {
    if (sheet.sheet_template) {
      setTabs(await getSheetFunc(sheet.sheet_template, "tabs"));
    }
  };

  useEffect(() => {
    if (Object.keys(sheet).length > 0) {
      setTabsGetFunction(sheet);
    }
  }, [sheet]);

  useEffect(() => {
    const idx = tabs.findIndex((t) =>
      pathname.toLowerCase().startsWith(t.path.toLowerCase())
    );
    let timeOutId = undefined;
    if (idx >= 0) {
      setTabValue(idx);
      timeOutId = delaySetTabValue(idx, 500);
    }
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [pathname]);

  useEffect(() => {
    const idx = tabs.findIndex((t) =>
      pathname.toLowerCase().startsWith(t.path.toLowerCase())
    );
    let timeOutId = undefined;
    if (idx >= 0) {
      delaySetTabValue(idx, 400);
    }
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [tabs]);

  useEffect(() => {
    const currentTabValue = tabValue;
    const timeOutId = delaySetTabValue(currentTabValue);
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [miniSidenav]);

  const renderTab = (e) => {
    return (
      <Tab
        key={e.key}
        label={t(e.name)}
        onClick={() => {
          navigate(e.path);
        }}
      />
    );
  };

  const sheetElement = () => {
    if (!activeSheet && !sheet_uuid) {
      return <PleaseSelectSheet />;
    }
    if (sheet.is_invalid_uuid) {
      return <InvalidUuid />;
    }
    if (Object.keys(sheet).length === 0) {
      return <FullPageLoading />;
    }
    if (tabs.length > 0) {
      if (tabs.at(tabValue).sheetElement) {
        return tabs.at(tabValue).sheetElement;
      }
    }
    return null;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
            >
              {tabs.map((e) => renderTab(e))}
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      {sheetElement()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeSheet: state.app.activeSheet,
    sheetList: state.sheet,
    uid: state.user.uid,
  };
};

const mapDispatchToProps = {
  selectActiveSheet,
  deselectActiveSheet,
  loadSheet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sheet);
