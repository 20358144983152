import { useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import signOut from "app/fb/signOut";
import { useEffect } from "react";

import FullPageLoading from "components/layouts/FullPageLoading";

const SignOut = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || "/"
  );
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(redirectTo);
    }, 200);
    setTimeout(() => {
      navigate(redirectTo);
    }, 1000);
    setTimeout(signOut, 100);
    setTimeout(signOut, 1000);
    setTimeout(signOut, 2000);
  }, []);

  return <FullPageLoading />;
};

export default SignOut;
