import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiInput from "components/sui/SuiInput";

import { useTranslation } from "react-i18next";
import useDetectBreakpoint from "app/hooks/useDetectBreakPoint";
import { NavLink } from "react-router-dom";

import { BASE_PATH } from "settings";

const ProductList = ({ products }) => {
  const { t } = useTranslation();
  const [filterProducts, setFilterProducts] = useState(products);
  const [gridMode, setGridMode] = useState(true);
  const currentBreakpoint = useDetectBreakpoint();


  useEffect(() => {
    setFilterProducts(products);
  }, [products]);

  const filter = (e) => {
    if (!e.target.value) {
      setFilterProducts(products);
      return;
    }
    const arr1 = products.filter(
      (p) => p.product_name.search(new RegExp(e.target.value, "i")) >= 0
    );
    const arr2 = products.filter(
      (p) => p.product_desc.search(new RegExp(e.target.value, "i")) >= 0
    );
    const arr3 = products.filter((p) =>
      p.product_tags.find(
        (tag) => tag.search(new RegExp(e.target.value, "i")) >= 0
      )
    );
    setFilterProducts([...new Set([...arr1, ...arr2, ...arr3])]);
  };

  const ProductCard = ({ product }) => {
    return (
      <Grid item>
        <Card>
          <SuiBox p={2}>
            <SuiBox mb={2}>
              <SuiTypography variant="body2" fontWeight="bold">
                {t(product.product_name)}
              </SuiTypography>
            </SuiBox>

            <SuiBox lineHeight={0} mb={2}>
              <SuiTypography variant="caption">
                {t(product.product_desc)}
              </SuiTypography>
            </SuiBox>
            <SuiBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <NavLink
                to={`${BASE_PATH}/create-sheet?sheet_template=${product.product_code}`}
              >
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ px: { xs: 3, sm: 1, md: 3, lg: 2, xxl: 1.5 } }}
                >
                  {t("Create sheet")}
                </SuiButton>
              </NavLink>
              <SuiButton
                variant="gradient"
                color="secondary"
                size="small"
                sx={{ px: { xs: 3, sm: 1, md: 3, lg: 2, xxl: 1.5 } }}
              >
                {t("View details")}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </Card>
      </Grid>
    );
  };

  const renderProducts = (productsInput) => {
    if (gridMode) {
      const size = { xs: 12, sm: 6, md: 6, lg: 4, xl: 4, xxl: 3 };
      const noGroup = 12 / size[currentBreakpoint];

      let productGroup = [];

      for (let i = 0; i < noGroup; i++) {
        productGroup = [
          ...productGroup,
          productsInput.filter((p, idx) => {
            return idx % noGroup === i;
          }),
        ];
      }

      return (
        <>
          {productGroup.map((group, idx) => (
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              {...size}
              spacing={3}
              px={1}
              key={`col${idx}`}
            >
              {group.map((product) => (
                <ProductCard product={product} key={product.product_code} />
              ))}
            </Grid>
          ))}
        </>
      );
    } else {
      const size = { xs: 12, sm: 10, md: 8, lg: 7, xl: 6, xxl: 6 };
      return (
        <>
          <Grid
            container
            item
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            {...size}
            spacing={3}
            px={1}
          >
            {productsInput.map((product) => (
              <ProductCard product={product} key={product.product_code} />
            ))}
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <SuiBox pt={3}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item sx={{ flexGrow: 1 }} pl={0}>
            <SuiInput
              icon={{ component: "search", direction: "left" }}
              placeholder={t("Type to search sheet")}
              onChange={filter}
            />
          </Grid>
          <Grid item>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                alignItems: "center",
              }}
            >
              <SuiBox lineHeight={0}>
                <SuiTypography variant="caption">{t("Layout")}</SuiTypography>
              </SuiBox>
              <SuiBox
                lineHeight={0}
                onClick={() => setGridMode(true)}
                sx={{ cursor: "pointer" }}
              >
                <Icon
                  color={gridMode ? "dark" : "disabled"}
                  onClick={() => setGridMode(false)}
                >
                  grid_view
                </Icon>
              </SuiBox>
              <SuiBox
                lineHeight={0}
                onClick={() => setGridMode(false)}
                sx={{ cursor: "pointer" }}
              >
                <Icon
                  color={!gridMode ? "dark" : "disabled"}
                  onClick={() => setGridMode(false)}
                >
                  view_headline
                </Icon>
              </SuiBox>
            </Stack>
          </Grid>
        </Grid>
      </SuiBox>

      {filterProducts.length > 0 && (
        <SuiBox py={3}>
          <Grid container spacing={2} justifyContent="center">
            {renderProducts(filterProducts)}
          </Grid>
        </SuiBox>
      )}
      {products.length > 0 && filterProducts.length <= 0 && (
        <SuiBox p={3} textAlign="center">
          <SuiTypography variant="body2" color="secondary">
            {t("No sheet found!")}
          </SuiTypography>
        </SuiBox>
      )}
    </>
  );
};

export default ProductList;
