import _ from "lodash";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import SuiBox from "components/sui/SuiBox";

import CreateSheetInitial from "./CreateSheetInitial";
import ProductList from "./ProductList";

import CodexAPI from "app/api/codexapi";
import { codexApiCatchError } from "actions/snackBar";

import FullPageLoading from "components/layouts//FullPageLoading";
import SuiTypography from "components/sui/SuiTypography";
import { connect } from "react-redux";

const CreateSheet = ({ codexApiCatchError }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const { t } = useTranslation();

  let sheet_template = searchParams.get("sheet_template");

  const loadProductList = async () => {
    try {
      const response = await CodexAPI().get("/market/product-list/");
      setProducts(response.data.data);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  useEffect(() => {
    loadProductList();
  }, []);

  useEffect(() => {
    setProduct(_.find(products, { product_code: sheet_template }));
  }, [products, sheet_template]);

  return (
    <>
      {!(products.length > 0) && <FullPageLoading />}
      {sheet_template && !product && (
        <SuiBox sx={{ justifyContent: "center" }}>
          <SuiBox>
            <SuiTypography
              variant="body2"
              color="error"
              mt={2}
              sx={{ justifyContent: "center" }}
            >
              {t("Sheet template - ")}
              {sheet_template} {t(" - is not found.")}{" "}
              {t("Please select valid sheet template.")}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
      {product && <CreateSheetInitial product={product} />}
      {!product && <ProductList products={products} />}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  codexApiCatchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSheet);
