import store from "store";

import authState from "app/fb/authState";
import { getCsrfToken, getCsrfTokenFromCookies } from "actions";

const onAppFirstLoad = async () => {
  const dispatch = store.dispatch;

  authState();
  dispatch(getCsrfTokenFromCookies());
  dispatch(getCsrfToken());
  setInterval(() => {
    dispatch(getCsrfToken());
  }, 1000 * 60 * 60 * 2);
};

export default onAppFirstLoad;
