import { auth } from "./config";

import { onAuthStateChanged } from "firebase/auth";

import store from "store";

import { signOut, reloadToken as actionReloadToken } from "actions";

import { TIME_TO_RELOAD_TOKEN } from "settings/fb";
import { loadCdxUser } from "actions/user";

import reloadToken from "app/fb/reloadToken";

const authState = () => {
  const dispatch = store.dispatch;

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      dispatch(actionReloadToken(user));
      setTimeout(
        reloadToken,
        user.stsTokenManager.expirationTime - Date.now() - 1000
      );
      setInterval(() => {
        reloadToken();
      }, TIME_TO_RELOAD_TOKEN);

      dispatch(loadCdxUser());
    } else {
      // User is not signed in
      dispatch(signOut());
    }
  });
};

export default authState;
