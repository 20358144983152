import _ from "lodash";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";

import Grid from "@mui/material/Grid";

import FullPageLoading from "components/layouts/FullPageLoading";

import pathCleansing from "app/utils/pathCleansing";
import CdxFolderItem from "components/cdx/CdxFolderItem";
import CdxFileItem from "components/cdx/CdxFileItem";

import NoFileInFolder from "./NoFileInFolder";

import { getMyFolder } from "actions";

const MyFolder = ({ hasLoadFolder, folder, getMyFolder }) => {
  const { pathname } = useLocation();
  const [path, setPath] = useState("/");
  const [select, setSelect] = useState([]);

  useEffect(() => {
    const paths = decodeURI(pathname).split("/");
    setPath(pathCleansing(paths.slice(2).join("/") || "/"));
    setSelect([]);
  }, [pathname]);

  useEffect(() => {
    getMyFolder();
  }, [pathname]);

  const handleFolderSelect = (folderFullPath) => {
    const fileList = folder.filter((f) =>
      f.sheet_path.startsWith(folderFullPath)
    );
    setSelect([...select, fileList]);
  };

  useEffect(() => {}, []);

  const renderFolder = () => {
    let folders = _.uniqBy(folder, "sheet_path")
      .map((s) => s.sheet_path)
      .map((p) => {
        const replaced_p = p.replace(new RegExp(path), "");
        if (p.length !== replaced_p.length && replaced_p.length > 0) {
          const folderName = replaced_p.slice(0, replaced_p.search(/\//));
          return {
            folderName,
            folderFullPath: `${path}${folderName}/`,
          };
        }
      })
      .filter((v) => v);

    folders = _.uniqBy(folders, "folderName");
    folders.sort((a, b) => {
      if (a.folderName > b.folderName) {
        return 1;
      }
      if (a.folderName < b.folderName) {
        return -1;
      }
      return 0;
    });

    const files = folder.filter((s) => s.sheet_path === path);
    files.sort((a, b) => {
      if (a.sheet_name > b.sheet_name) {
        return 1;
      }
      if (a.sheet_name < b.sheet_name) {
        return -1;
      }
      return 0;
    });

    if (hasLoadFolder) {
      return (
        <Grid container spacing={2}>
          {folders.length === 0 && files.length === 0 && <NoFileInFolder />}
          {folders.length > 0 && (
            <Grid container item spacing={2}>
              {folders.map((s) => (
                <CdxFolderItem key={s.folderFullPath} {...s} />
              ))}
            </Grid>
          )}
          {files.length > 0 && (
            <Grid container item spacing={2}>
              {files.map((f) => (
                <CdxFileItem key={f.sheet_uuid} file={f} />
              ))}
            </Grid>
          )}
        </Grid>
      );
    } else {
      return <FullPageLoading />;
    }
  };

  return <SuiBox mt={3}>{renderFolder()}</SuiBox>;
};

const mapStateToProps = (state) => {
  return {
    hasLoadFolder: state.app.hasLoadFolder,
    folder: state.folder,
  };
};

const mapDispatchToProps = { getMyFolder };

export default connect(mapStateToProps, mapDispatchToProps)(MyFolder);
