import {
  DEPLOY_SNACKBAR,
  UPDATE_SNACKBAR,
  UPDATE_CSRF_TOKEN,
} from "actions/types";

import { v4 as uuidv4 } from "uuid";

import CodexAPI from "app/api/codexapi";
import Cookies from "js-cookie";

import { apiServerError } from "actions/snackBar";
import { LSK_CDX_ACTIVE_SHEET } from "settings/localStorageKeys";

import { SELECT_ACTIVE_SHEET, DESELECT_ACTIVE_SHEET } from "./types";

import encrypt from "app/utils/encrypt";
import cleanUuid from "app/utils/cleanUuid";

export const deploySnackbar = ({
  color = "info",
  icon = "notifications",
  title = "",
  content = "",
  timeout = 1000 * 10,
}) => {
  const snackBar = {
    color,
    icon,
    title,
    content,
    timestamp: Date.now(),
    timeout,
    uuid: uuidv4(),
  };
  return {
    type: DEPLOY_SNACKBAR,
    payload: { newSnackBar: snackBar },
  };
};

export const destroySnackbar = (uuid) => (dispatch, getState) => {
  const currentSnackBars = getState().app.snackBars.filter(
    (element) => element.uuid !== uuid
  );

  dispatch({
    type: UPDATE_SNACKBAR,
    payload: { newSnackBars: currentSnackBars },
  });
};

export const getCsrfToken = () => async (dispatch, getState) => {
  try {
    const response = await CodexAPI().get("/getcsrftoken/");
    Cookies.set("csrfToken", response.data.data.csrfToken);
    dispatch({
      type: UPDATE_CSRF_TOKEN,
      payload: response.data.data,
    });
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const getCsrfTokenFromCookies = () => (dispatch, getState) => {
  const csrfToken = Cookies.get("csrfToken");
  if (csrfToken) {
    dispatch({
      type: UPDATE_CSRF_TOKEN,
      payload: { csrfToken },
    });
  }
};

export const selectActiveSheet = (sheet_uuid) => (dispatch, getState) => {
  const clean_uuid = cleanUuid(sheet_uuid);
  const uid = getState().user.uid;
  window.localStorage.setItem(
    LSK_CDX_ACTIVE_SHEET,
    encrypt({ uid: uid, uuid: clean_uuid })
  );

  dispatch({
    type: SELECT_ACTIVE_SHEET,
    payload: clean_uuid,
  });
};

export const deselectActiveSheet = () => {
  window.localStorage.removeItem(LSK_CDX_ACTIVE_SHEET);
  return {
    type: DESELECT_ACTIVE_SHEET,
  };
};
