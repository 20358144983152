import { connect } from "react-redux";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSearchParams, Navigate } from "react-router-dom";

import AuthLayout from "components/layouts/AuthLayout";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import ResetPasswordForm from "./form";
import isSignInWithEmailLink from "app/fb/isSignInWithEmailLink";

import { BASE_APP_PATH } from "settings/routes";
import PasswordPolicy from "./passwordPolicy";

const ResetPassword = ({ isSignIn }) => {
  const { t } = useTranslation();
  const [validLink, setValidLink] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || BASE_APP_PATH
  );

  useEffect(() => {
    setValidLink(isSignInWithEmailLink());
  }, []);

  return (
    <>
      {!isSignIn ? (
        <AuthLayout>
          <SuiBox
            p={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            width="100%"
          >
            <SuiBox>
              <SuiTypography variant="h5">{t("Reset password")}</SuiTypography>
            </SuiBox>
            {validLink ? (
              <>
                <ResetPasswordForm />
                <PasswordPolicy />
              </>
            ) : (
              <SuiBox mt={3} width="100%">
                <SuiTypography variant="body2" color="error">
                  {t("Invalid link")}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </AuthLayout>
      ) : (
        <Navigate to={redirectTo} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { isSignIn: state.app.isSignIn };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
