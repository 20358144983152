export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const RELOAD_TOKEN = "RELOAD_TOKEN";
export const LOAD_CDX_USER = "LOAD_CDX_USER";
export const UPDATE_CSRF_TOKEN = "UPDATE_CSRF_TOKEN";

export const DEPLOY_SNACKBAR = "DEPLOY_SNACKBAR";
export const UPDATE_SNACKBAR = "UPDATE_SNACKBAR";

export const UPDATE_USER_SETTING = "UPDATE_USER_SETTING";
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";

export const GET_MY_FOLDER = "GET_MY_FOLDER";
export const MY_FOLDER_HASH = "MY_FOLDER_HASH";

export const LOAD_SHEET = "LOAD_SHEET";
export const SHEET_HASH = "SHEET_HASH";

export const SELECT_ACTIVE_SHEET = "SELECT_ACTIVE_SHEET";
export const DESELECT_ACTIVE_SHEET = "DESELECT_ACTIVE_SHEET";

export const GET_WALLET_DETAIL = "GET_WALLET_DETAIL";
export const WALLET_DETAIL_HASH = "WALLET_DETAIL_HASH";
