import { useState, useEffect } from "react";
import theme from "assets/theme";

const useDetectBreakpoint = (id = 0) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [bp, setBp] = useState(null);

  useEffect(() => {
    const observer = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", observer);

    return () => window.removeEventListener("resize", observer);
  }, []);

  useEffect(() => {
    const breakPoints = Object.values(theme.breakpoints.values).reverse();
    const breakPointNames = Object.keys(theme.breakpoints.values).reverse();

    try {
      setBp(breakPointNames.at(-1));

      for (let i = 0; i < breakPoints.length; i++) {
        if (width >= breakPoints.at(i)) {
          setBp(breakPointNames.at(i));
          break;
        }
      }
    } catch {
      setBp("lg");
    }
  }, [width]);

  return bp;
};

export default useDetectBreakpoint;
