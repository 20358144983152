import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Item from "./Item";
import Divider from "@mui/material/Divider";
import { BASE_PATH } from "settings";

import { useTranslation } from "react-i18next";

import signOut from "app/fb/signOut";
import RenameModal from "./RenameModal";
import MoveModal from "./MoveModal";

const FileMenu = ({ openMenu, handleCloseMenu, file }) => {
  const { t } = useTranslation();
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);

  return (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <Item
        title={t("Rename")}
        onClick={() => {
          setOpenRenameModal(true);
        }}
        sx={{ cursor: "pointer" }}
      />
      <Item
        title={t("Move")}
        onClick={() => {
          setOpenMoveModal(true);
        }}
        sx={{ cursor: "pointer" }}
      />
      <RenameModal
        open={openRenameModal}
        handleClose={() => {
          setOpenRenameModal(false);
          handleCloseMenu();
        }}
        file={file}
      />
      <MoveModal
        open={openMoveModal}
        handleClose={() => {
          setOpenMoveModal(false);
          handleCloseMenu();
        }}
        file={file}
      />
    </Menu>
  );
};

export default FileMenu;
