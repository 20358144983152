import { combineReducers } from "redux";
import appReducer from "reducers/app";
import userReducer from "reducers/user";
import hashDictionaryReducer from "reducers/hashDictionary";
import folderReducer from "reducers/folder";
import sheetReducers from "reducers/sheet";

export default combineReducers({
  app: appReducer,
  hashDictionary: hashDictionaryReducer,
  user: userReducer,
  folder: folderReducer,
  sheet: sheetReducers,
});
