import { connect } from "react-redux";

import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { useSearchParams, Navigate, useNavigate } from "react-router-dom";

import AuthLayout from "components/layouts/AuthLayout";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import isSignInWithEmailLink from "app/fb/isSignInWithEmailLink";

import { BASE_APP_PATH } from "settings/routes";

import VerifyEmailForm from "./form";

const VerifyEmail = ({ emailVerified, isSignIn }) => {
  const { t } = useTranslation();
  const [validLink, setValidLink] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || BASE_APP_PATH
  );

  const navigate = useNavigate();

  useEffect(() => {
    setValidLink(isSignInWithEmailLink());
  }, []);

  const body = (
    <SuiBox
      p={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      width="100%"
    >
      <SuiBox>
        <SuiTypography variant="h5">{t("Email sign in")}</SuiTypography>
      </SuiBox>
      {validLink ? (
        <VerifyEmailForm />
      ) : (
        <SuiBox mt={3} width="100%">
          <SuiTypography variant="body2" color="error">
            {t("Invalid link")}
          </SuiTypography>
        </SuiBox>
      )}
    </SuiBox>
  );

  return (
    <Fragment>
      {!emailVerified ? (
        <Fragment>
          {isSignIn ? (
            <SuiBox
              mt={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              maxSize="50rem"
            >
              {body}
            </SuiBox>
          ) : (
            <AuthLayout>{body}</AuthLayout>
          )}
        </Fragment>
      ) : (
        <Navigate to={redirectTo} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    emailVerified: state.user.fbData.emailVerified,
    isSignIn: state.app.isSignIn,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
