const pathCleansing = (value) => {
  let path = `/${value}/`.replace(/\\/g, "/");

  let old_path = "";
  while (old_path !== path) {
    old_path = path;
    path = path.replace(/\/\//g, "/");
  }

  return path;
};

export default pathCleansing;
