import { useState, useEffect, Fragment } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { useSoftUIController, setMiniSidenav } from "context";

import CdxLayout from "components/layouts/Containter/CdxLayout";
import CdxFooter from "components/cdx/CdxFooter";
import CdxNavbar from "components/cdx/CdxNavbar";
import CdxSidenav from "components/cdx/CdxSidenav";

import math96 from "assets/math96.png"; // CDX IMPORTANT - To Replace brand

import { authRoutes } from "routes";

import RoutesRender from "app/utils/RoutesRender";
import sheetRoutes from "app/sheetRoutes";

const AuthApp = ({ sheet, emailVerified }) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const defaultRoutes = [...authRoutes];

  const [combinedRoutes, setCombinedRoutes] = useState(defaultRoutes);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    const redirect_to = searchParams.get("redirect_to");

    if (emailVerified === false && !pathname.startsWith("/sign-out")) {
      if (redirect_to && !pathname.startsWith("/verify-email")) {
        navigate(redirect_to);
      } else if (!pathname.startsWith("/verify-email")) {
        navigate(`/verify-email-request?redirect_to=${pathname}`);
      }
    } else if (pathname === "/" && redirect_to) {
      navigate(redirect_to);
    }
  }, [pathname]);

  const loadSheet = async () => {
    setCombinedRoutes([...(await sheetRoutes(sheet)), ...defaultRoutes]);
  };

  useEffect(() => {
    if (sheet.sheet_template) {
      loadSheet(sheet);
    } else {
      setCombinedRoutes(defaultRoutes);
    }
  }, [sheet]);

  return (
    <>
      <CdxSidenav
        color={sidenavColor}
        brand={math96} // CDX IMPORTANT - To Replace brand
        brandName="Codex Sheet"
        routes={combinedRoutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <CdxLayout>
        <CdxNavbar />
        <RoutesRender routes={combinedRoutes} />
        <CdxFooter />
      </CdxLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailVerified: state.user.fbData.emailVerified,
    sheet: state.sheet[state.app.activeSheet] || {},
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthApp);
