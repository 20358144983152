import {
  SIGN_IN,
  SIGN_OUT,
  RELOAD_TOKEN,
  LOAD_CDX_USER,
  GET_WALLET_BALANCE,
  GET_WALLET_DETAIL,
} from "actions/types";

const userDefault = {
  email: "",
  fbData: {},
  token: "",
  tokenExpireAt: "",
  user_data: {},
  user_setting: {},
  wallet: {},
  wallet_detail: { receipt: [], invoice: [] },
};

const userReducer = (user = userDefault, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...user, ...action.payload.user };
    case RELOAD_TOKEN:
      return { ...user, ...action.payload.user };
    case SIGN_OUT:
      return { ...userDefault };
    case LOAD_CDX_USER:
      return { ...user, ...action.payload };
    case GET_WALLET_BALANCE:
      return { ...user, wallet: action.payload };
    case GET_WALLET_DETAIL:
      return { ...user, wallet_detail: action.payload };
    default:
      return user;
  }
};

export default userReducer;
