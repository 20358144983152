import { Icon } from "@mui/material";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import { useState } from "react";

const CdxTransparentCollapseCard = ({
  children,
  title,
  titleSize = "h6",
  spaceBetween = 2,
  defaultCollapse = false,
  ...rest
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);
  return (
    <SuiBox
      bgColor={collapse ? "white" : "transparent"}
      borderRadius="xl"
      shadow={collapse ? "lg" : "none"}
      {...rest}
    >
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiBox>
          <SuiTypography variant={titleSize}>{title}</SuiTypography>
        </SuiBox>
        <SuiBox
          onClick={() => {
            setCollapse(!collapse);
          }}
          lineHeight={0}
          sx={{ cursor: "pointer" }}
        >
          <Icon>{collapse ? "chevron_left" : "expand_more"}</Icon>
        </SuiBox>
      </SuiBox>
      {!collapse && children && <SuiBox mt={spaceBetween}>{children}</SuiBox>}
    </SuiBox>
  );
};

export default CdxTransparentCollapseCard;
