import { BASE_PATH } from "settings";
import { Navigate } from "react-router-dom";

import SignIn from "pages/sign-in";
import SignUp from "pages/sign-up";
import SignOut from "pages/sign-out";
import ResetPassword from "pages/reset-password";
import ResetPasswordRequest from "pages/reset-password-request";
import VerifyEmail from "pages/verify-email";

import Sheet from "pages/sheet";

import NotFound404 from "pages/NotFound404";
import MyFolder from "pages/my-folder";
import CreateSheet from "pages/create-sheet";
import Wallet from "pages/wallet";
import ContactSupport from "pages/contact-support";
import VerifyEmailRequest from "pages/verify-email-request";

export const normalRoutes = [];

export const nonAuthRoutes = [
  { key: "/sign-in", path: `/sign-in`, element: <SignIn /> },
  { key: "/sign-up", path: `/sign-up`, element: <SignUp /> },
  {
    key: "/reset-password",
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  {
    key: "/reset-password-request",
    path: `/reset-password-request`,
    element: <ResetPasswordRequest />,
  },
  { key: "/verify-email/*", path: `/verify-email/*`, element: <VerifyEmail /> },
  { key: "/sheet/*", path: `/sheet/*`, signInRequired: true },
  { key: "/my-folder/*", path: `/my-folder/*`, signInRequired: true },
  // {
  //   key: "/confirmer/*",
  //   path: `/confirmer/*`,
  //   signInRequired: true,
  //   signInPage: "/confirmer/sign-in",
  // },
  // { key: "/not-found/nonAuth", path: `*`, element: <NotFound404 /> },
  {
    key: "/not-found/nonAuth",
    path: `${BASE_PATH}/*`,
    element: <Navigate to={`${BASE_PATH}/sign-in`} />,
  },
];

export const authRoutes = [
  {
    type: "nocollapse",
    name: "My folder",
    icon: "folder",
    key: "/my-folder",
    path: `${BASE_PATH}/my-folder`,
    element: <MyFolder />,
  },
  {
    key: "/my-folder/*",
    path: `${BASE_PATH}/my-folder/*`,
    element: <MyFolder />,
  },
  {
    type: "nocollapse",
    name: "Create sheet",
    icon: "add",
    key: "/create-sheet",
    path: `${BASE_PATH}/create-sheet`,
    element: <CreateSheet />,
  },
  {
    type: "nocollapse",
    name: "My wallet",
    icon: "account_balance_wallet",
    key: "/my-wallet",
    path: `${BASE_PATH}/my-wallet`,
    element: <Wallet />,
  },

  { type: "divider", key: "divider-resource" },
  { type: "title", title: "Resource", key: "/resource" },

  {
    type: "nocollapse",
    name: "Contact support",
    icon: "contact_support",
    key: "/contact-support",
    path: `${BASE_PATH}/contact-support`,
    element: <ContactSupport />,
  },
  // {
  //   type: "nocollapse",
  //   name: "Help center",
  //   icon: "help",
  //   key: "/help-center",
  //   path: `${BASE_PATH}/help-center`,
  //   element: <NotFound404 />,
  // },
  // {contact_support
  //   key: "/profile-&-setting",
  //   path: `${BASE_PATH}/profile-&-setting`,
  //   element: <Profile />,
  // },
  {
    key: "/verify-email-request",
    path: `/verify-email-request`,
    element: <VerifyEmailRequest />,
  },
  {
    key: "/reset-password",
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  { key: "/verify-email/*", path: `/verify-email/*`, element: <VerifyEmail /> },
  {
    key: "/sheet/*",
    path: `${BASE_PATH}/sheet/*`,
    element: <Sheet />,
  },
  { key: "/sign-out", path: `${BASE_PATH}/sign-out`, element: <SignOut /> },
  {
    key: "/not-found/auth",
    path: `${BASE_PATH}/*`,
    element: <Navigate to={`${BASE_PATH}/my-folder`} />,
  },
];
