import { BASE_PATH } from "settings";

import getSheetFunc from "app/hooks/getSheetFunc";
import NotFound404 from "pages/NotFound404";

import CloseSheet from "pages/sheet/static/close-sheet";

const sheetRoutes = async (sheet) => {
  const displaySheetName = (name) => {
    if (name.length >= 15) {
      return `${name.slice(0, 15)}...`;
    }
    return name;
  };

  const tabs = async () => {
    const getTabs = await getSheetFunc(sheet.sheet_template, "tabs");

    if (getTabs) {
      return getTabs;
    }
    return [];
  };

  return [
    {
      type: "collapse",
      name: displaySheetName(sheet.sheet_name),
      restrictTranslation: true,
      key: "/sheet",
      path: `${BASE_PATH}/sheet`,
      icon: "edit",
      collapse: [
        ...(await tabs()),
        {
          name: "Action",
          key: "/sheet/action",
          path: `${BASE_PATH}/sheet/action`,
          collapse: [
            {
              name: "Close",
              key: "Close",
              path: `${BASE_PATH}/s/action/close`,
              element: <CloseSheet />,
            },
          ],
        },
      ],
    },
    { type: "divider", key: "divider-sheet" },
  ];
};

export default sheetRoutes;
