import { LOAD_SHEET, SHEET_HASH } from "./types";
import CodexAPI from "app/api/codexapi";

import { apiServerError } from "actions/snackBar";
import { deploySnackbar } from "actions";
import cleanUuid from "app/utils/cleanUuid";

export const loadSheet = (sheet_uuid) => async (dispatch, getState) => {
  const clean_uuid = cleanUuid(sheet_uuid);
  const prevHash = getState().hashDictionary[SHEET_HASH];
  try {
    let response = await CodexAPI().get("/sheet/get-sheet/hash/", {
      params: { sheet_uuid: clean_uuid },
    });
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/sheet/get-sheet/", {
        params: { sheet_uuid: clean_uuid, hash: newHash },
      });
      dispatch({
        type: SHEET_HASH,
        payload: { [clean_uuid]: newHash },
      });
      dispatch({
        type: LOAD_SHEET,
        payload: { [clean_uuid]: response.data.data },
      });
    }
    if ([1, 2].includes(response.data.data.status)) {
      setTimeout(() => {
        dispatch(getSheetStatusUpdate(sheet_uuid));
      }, 1000 * 30);
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    } else if ([404, 422].includes(error.response.status)) {
      dispatch({
        type: LOAD_SHEET,
        payload: { [clean_uuid]: { is_invalid_uuid: true } },
      });
    }
  }
};

const getSheetStatusUpdate = (sheet_uuid) => async (dispatch, getState) => {
  try {
    const response = await CodexAPI().get("/sheet/get-sheet-status/", {
      params: { sheet_uuid: sheet_uuid },
    });
    if ([1, 2].includes(response.data.data)) {
      setTimeout(() => {
        dispatch(getSheetStatusUpdate(sheet_uuid));
      }, 1000 * 30);
    } else {
      dispatch(loadSheet(sheet_uuid));
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Processed completed",
        content: `Please check your sheet`,
      };
      dispatch(deploySnackbar(snackBar));
    }
  } catch (error) {}
};
