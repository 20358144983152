import { useState } from "react";

import SuiBox from "components/sui/SuiBox";
import CdxInputIconClickable from "components/cdx/CdxInputIconClickable";
import SuiTypography from "components/sui/SuiTypography";

const CdxInput = ({ label, mb, error, success, message, ...inputProps }) => {
  const [visible, setVisible] = useState(false);

  const color = () => {
    if (error) {
      return "error";
    } else if (success) {
      return "success";
    } else {
      return "inherit";
    }
  };

  return (
    <>
      {label && (
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox mb={mb ? mb : 0}>
        {visible ? (
          <CdxInputIconClickable
            {...inputProps}
            error={error}
            success={success}
            type="input"
            icon={{
              component: "visibility",
              direction: "right",
              onClick: () => {
                setVisible(false);
              },
            }}
          />
        ) : (
          <CdxInputIconClickable
            {...inputProps}
            error={error}
            success={success}
            type="password"
            icon={{
              component: "visibility_off",
              direction: "right",
              onClick: () => {
                setVisible(true);
              },
            }}
          />
        )}
        {message && (
          <SuiBox px={1.5} sx={{ lineHeight: 1 }}>
            <SuiTypography color={color()} variant="caption">
              {message}
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
    </>
  );
};

export default CdxInput;
