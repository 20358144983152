import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";

import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiTagInput from "components/sui/SuiTagInput";

import CodexAPI from "app/api/codexapi";
import CdxInput from "components/cdx/CdxInput";

import { getMyFolder } from "actions";

import pathCleansing from "app/utils/pathCleansing";
import { BASE_PATH } from "settings";
import { connect } from "react-redux";
import { codexApiCatchError } from "actions/snackBar";

const CreateSheetForm = ({
  product,
  folder,
  getMyFolder,
  codexApiCatchError,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();

  const validateForm = (values) => {
    const error = {};
    const filter_folder = folder.filter((e) => {
      return (
        e.sheet_name === values.sheet_name && e.sheet_path === values.sheet_path
      );
    });
    if (!values.sheet_name) {
      error.sheet_name = "Please insert sheet name";
    } else if (values.sheet_name.length > 256) {
      error.sheet_name = "Sheet name should no longer than 256 characters";
    }
    if (filter_folder.length > 0) {
      error.sheet_name = "Duplicate file name in current path";
    }
    return error;
  };

  const onSubmit = async (values) => {
    const data = {
      sheet_template: product.product_code,
      sheet_tags: tags,
      ...values,
    };

    try {
      const response = await CodexAPI().post("/folder/create-sheet/", data);

      if (response.status === 201) {
        getMyFolder();
        navigate(
          `${BASE_PATH}/sheet/?sheet_uuid=${response.data.data.sheet_uuid}`
        );
      }
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  const onPathBlur = (e, input) => {
    const path = pathCleansing(e.target.value);
    input.onChange(path);
    input.onBlur(e);
  };

  return (
    <SuiBox p={3}>
      <SuiTypography>
        {t("Create")}
        {" - "}
        {t(product.product_name)}{" "}
      </SuiTypography>

      <Form
        onSubmit={onSubmit}
        validate={validateForm}
        render={({ handleSubmit, form, submitError, submitting, pristine }) => (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={1}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("Template name")}
                </SuiTypography>
              </SuiBox>
              <CdxInput value={product.product_name} mb={1} disabled />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("Template version")}
                </SuiTypography>
              </SuiBox>
              <CdxInput value={product.product_version} mb={1} disabled />
            </Grid>

            <Grid item xs={12} md={6} lg={4} xl={3}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("Sheet name")}*
                </SuiTypography>
              </SuiBox>
              <Field name="sheet_name">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={1}
                    error={!!(meta.error && meta.touched)}
                    message={meta.error && meta.touched && t(meta.error)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("Sheet path")}
                </SuiTypography>
              </SuiBox>
              <Field name="sheet_path" initialValue="/">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={(e) => {
                      onPathBlur(e, input);
                    }}
                    mb={1}
                    error={!!(meta.error && meta.touched)}
                    message={meta.error && meta.touched && t(meta.error)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("Sheet description")}
                </SuiTypography>
              </SuiBox>
              <Field name="sheet_desc">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={1}
                    error={!!(meta.error && meta.touched)}
                    message={meta.error && meta.touched && t(meta.error)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("Sheet tags")}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1}>
                <SuiTagInput
                  placeholder=" "
                  tags={tags}
                  onChange={(e) => {
                    setTags(e);
                  }}
                />
              </SuiBox>
            </Grid>
            {submitError && (
              <Grid xs={12}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
            <Grid item container xs={12} columnSpacing={2}>
              <Grid item>
                <SuiButton variant="gradient" color="info" type="submit">
                  {t("Create sheet")}
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  variant="gradient"
                  color="error"
                  onClick={form.reset}
                >
                  {t("Reset")}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return { folder: state.folder };
};

const mapDispatchToProps = {
  getMyFolder,
  codexApiCatchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSheetForm);
