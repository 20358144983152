import { Fragment, useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import signOut from "app/fb/signOut";
import { useEffect } from "react";

import { connect } from "react-redux";

import { deselectActiveSheet } from "actions";

const CloseSheet = ({ deselectActiveSheet }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || "/"
  );

  const navigate = useNavigate();

  useEffect(() => {
    deselectActiveSheet();
    navigate(redirectTo);
  }, []);

  return <Fragment></Fragment>;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  deselectActiveSheet,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseSheet);
