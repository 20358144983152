import { LOAD_SHEET } from "actions/types";

const sheetDefault = {};

const sheetReducers = (sheet = sheetDefault, action) => {
  switch (action.type) {
    case LOAD_SHEET:
      return { ...sheet, ...action.payload };
    default:
      return sheet;
  }
};

export default sheetReducers;
