import { useTranslation } from "react-i18next";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

const PasswordPolicy = () => {
  const { t } = useTranslation();

  return (
    <SuiBox px={3} component="ul">
      <SuiBox py={1} component="li" lineHeight={0.5}>
        <SuiTypography variant="caption">
          {t("Must contain at least one uppercase letter")}
        </SuiTypography>
      </SuiBox>
      <SuiBox py={1} component="li" lineHeight={0.5}>
        <SuiTypography variant="caption">
          {t("Must contain at least one lowercase letter")}
        </SuiTypography>
      </SuiBox>
      <SuiBox py={1} component="li" lineHeight={0.5}>
        <SuiTypography variant="caption">
          {t("Must contain at least one number")}
        </SuiTypography>
      </SuiBox>
      <SuiBox py={1} component="li" lineHeight={0.5}>
        <SuiTypography variant="caption">
          {t("Must contain at least one special character (!@#$%^&*()+-/.)")}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export default PasswordPolicy;
