import { GET_MY_FOLDER } from "actions/types";

const folderDefault = [];

const folderReducer = (folder = folderDefault, action) => {
  switch (action.type) {
    case GET_MY_FOLDER:
      return action.payload;
    default:
      return folder;
  }
};

export default folderReducer;
