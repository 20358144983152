import SuiBox from "components/sui/SuiBox";

const CdxCard = ({ children, ...rest }) => {
  return (
    <SuiBox bgColor="white" borderRadius="xl" shadow="lg" {...rest}>
      {children}
    </SuiBox>
  );
};

export default CdxCard;
