import {
  SIGN_IN,
  SIGN_OUT,
  RELOAD_TOKEN,
  UPDATE_CSRF_TOKEN,
  DEPLOY_SNACKBAR,
  UPDATE_SNACKBAR,
  GET_MY_FOLDER,
  SELECT_ACTIVE_SHEET,
  DESELECT_ACTIVE_SHEET,
} from "actions/types";

const appDefault = {
  isSignIn: null,
  isEmailSignIn: null,
  userLastActive: Date.now(),
  snackBars: [],
  csrfToken: "",
  hasLoadFolder: false,
  activeSheet: "",
};

const appReducer = (app = appDefault, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...app, ...action.payload.app, isSignIn: true };
    case SIGN_OUT:
      return {
        ...app,
        isSignIn: false,
        isEmailSignIn: false,
        hasLoadFolder: false,
        activeSheet: "",
      };
    case RELOAD_TOKEN:
      return { ...app, ...action.payload.app, isSignIn: true };
    case UPDATE_CSRF_TOKEN:
      return { ...app, csrfToken: action.payload.csrfToken };
    case DEPLOY_SNACKBAR:
      return {
        ...app,
        snackBars: [...app.snackBars, action.payload.newSnackBar],
      };
    case UPDATE_SNACKBAR:
      return {
        ...app,
        snackBars: action.payload.newSnackBars,
      };
    case GET_MY_FOLDER:
      return { ...app, hasLoadFolder: true };
    case SELECT_ACTIVE_SHEET:
      return { ...app, activeSheet: action.payload };
    case DESELECT_ACTIVE_SHEET:
      return { ...app, activeSheet: appDefault.activeSheet };
    default:
      return app;
  }
};

export default appReducer;
