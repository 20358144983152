import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";



import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import VerifyEmailRequestForm from "./requestForm";

const VerifyEmailRequest = () => {
  const { t } = useTranslation();

  return (
    <SuiBox
      mt={3}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SuiBox
        p={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        width="100%"
        maxWidth="50rem"
      >
        <SuiBox>
          <SuiTypography variant="h5">{t("Verify email")}</SuiTypography>
        </SuiBox>
        <VerifyEmailRequestForm />
      </SuiBox>
    </SuiBox>
  );
};

export default VerifyEmailRequest;
