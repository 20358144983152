/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for CdxInput
import CdxInputRoot from "components/cdx/CdxInputIconClickable/CdxInputRoot";
import CdxInputWithIconRoot from "components/cdx/CdxInputIconClickable/CdxInputWithIconRoot";
import CdxInputIconBoxRoot from "components/cdx/CdxInputIconClickable/CdxInputIconBoxRoot";
import CdxInputIconRoot from "components/cdx/CdxInputIconClickable/CdxInputIconRoot";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";

import SuiBox from "components/sui/SuiBox";

const CdxInput = forwardRef(
  ({ size, icon, error, success, disabled, ...rest }, ref) => {
    let template;
    const [controller] = useSoftUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    const iconOnClick = () => {
      if (icon.onClick) {
        icon.onClick();
      }
    };

    if (icon.component && icon.direction === "left") {
      template = (
        <CdxInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <CdxInputIconBoxRoot ownerState={{ size }}>
            <CdxInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </CdxInputIconRoot>
          </CdxInputIconBoxRoot>
          <CdxInputRoot
            {...rest}
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
        </CdxInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <CdxInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <CdxInputRoot
            {...rest}
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
          <SuiBox component="div" onClick={iconOnClick}>
            <CdxInputIconBoxRoot ownerState={{ size }}>
              <CdxInputIconRoot fontSize="small" ownerState={{ size }}>
                {icon.component}
              </CdxInputIconRoot>
            </CdxInputIconBoxRoot>
          </SuiBox>
        </CdxInputWithIconRoot>
      );
    } else {
      template = (
        <CdxInputRoot
          {...rest}
          ref={ref}
          ownerState={{ size, error, success, disabled }}
        />
      );
    }

    return template;
  }
);

// Setting default values for the props of CdxInput
CdxInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the CdxInput
CdxInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CdxInput;
