import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { BASE_PATH } from "settings";

const CdxFolderItem = ({ folderName, folderFullPath }) => {
  let navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xxl={2}
      onDoubleClick={() => {
        navigate(`${BASE_PATH}/my-folder${folderFullPath}`);
      }}
    >
      <SuiBox
        display="flex"
        alignItems="center"
        bgColor="white"
        borderRadius="md"
        shadow="xl"
        sx={{ userSelect: "none", cursor: "pointer" }}
      >
        <SuiTypography component="div" color="info" textGradient>
          <SuiBox
            p={2}
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <Icon fontSize="default" m={1}>
              folder
            </Icon>
          </SuiBox>
        </SuiTypography>
        <SuiBox pr={2} py={2} lineHeight={1}>
          <SuiTypography display="block" variant="button" fontWeight="medium">
            {folderName}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Grid>
  );
};

export default CdxFolderItem;
