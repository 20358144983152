const getSheetFunc = async (template_code, sub_module = null) => {
  const sheetCode = template_code.slice(0, template_code.search(/_/));

  try {
    const template = await import(`sheets/${sheetCode}/${template_code}`);
    if (template && sub_module) {
      return template[sub_module];
    } else if (template) {
      return template;
    }
  } catch {
    const template = await import(`sheets/not-found`);
    if (template && sub_module) {
      return template[sub_module];
    } else if (template) {
      return template;
    }
  }
};

export default getSheetFunc;
