import { auth } from "./config";

import isSignInWithEmailLink from "./isSignInWithEmailLink";

import { signInWithEmailLink as fbSignInWithEmailLink } from "firebase/auth";
import encrypt from "app/utils/encrypt";

import {
  LSK_EMAIL_FOR_SIGN_IN,
  LSK_CDX_IS_EMAIL_SIGNIN,
} from "settings/localStorageKeys";

import reloadToken from "app/fb/reloadToken";

import store from "store";

import { deploySnackbar } from "actions";

const signInWithEmailLink = async (email = null, isSignIn = false) => {
  let result = {};
  if (email === null) {
    email = window.localStorage.getItem(LSK_EMAIL_FOR_SIGN_IN);
  }
  if (isSignInWithEmailLink()) {
    if (!isSignIn) {
      window.localStorage.setItem(
        LSK_CDX_IS_EMAIL_SIGNIN,
        encrypt({ email: email, isEmailSignIn: true })
      );
    }

    await fbSignInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem(LSK_EMAIL_FOR_SIGN_IN);

        window.localStorage.setItem(
          LSK_CDX_IS_EMAIL_SIGNIN,
          encrypt({ email: email, isEmailSignIn: true })
        );
        reloadToken();

        result = { type: "success", data: { ...result } };
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        window.localStorage.removeItem(LSK_CDX_IS_EMAIL_SIGNIN);
        reloadToken();

        if (isSignIn) {
          const dispatch = store.dispatch;
          const snackBar = {
            color: "error",
            icon: "report_problem",
            title: "Invalid sign in link",
            content: `Please make another request`,
          };
          dispatch(deploySnackbar(snackBar));
        }

        result = { type: "error", data: { ...error } };
      });
  } else {
    result = { type: "error", data: { code: "invalid link" } };
  }

  return result;
};

export default signInWithEmailLink;
