import { parse, stringify } from "uuid";

export default function cleanUuid(uuid) {
  uuid = uuid.replace(/-/gi, "");
  uuid =
    uuid.substr(0, 8) +
    "-" +
    uuid.substr(8, 4) +
    "-" +
    uuid.substr(12, 4) +
    "-" +
    uuid.substr(16, 4) +
    "-" +
    uuid.substr(20);
  try {
    return stringify(parse(uuid));
  } catch {
    return uuid;
  }
}
