import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";

const PleaseSelectSheet = () => {
  const { t } = useTranslation();

  return (
    <SuiBox
      py={20}
      textAlign="center"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <SuiBox p={2}>
        <SuiTypography variant="h1">
          <Icon fontSize="inherit">error_outline</Icon>
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <SuiTypography variant="header1" color="secondary">
          {t("Please select a sheet or create one")}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export default PleaseSelectSheet;
