/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

import { useTranslation } from "react-i18next";

import { BASE_URL } from "settings";

import { useState, useEffect } from "react";
import { useRef } from "react";

import { PRIVACY_POLICY } from "settings/routes";

import { useLocation } from "react-router-dom";

const CdxFooter = ({ company, links }) => {
  const { href, name } = company;
  const { size } = typography;
  const footerRef = useRef();
  const autoSpaceRef = useRef();
  const { pathname } = useLocation();

  const [autoSpace, setAutoSpace] = useState(0);

  const { t } = useTranslation('route');

  const autoCdxFooter = () => {
    if (!footerRef.current) {
      return;
    }

    const body = document.body;
    const html = document.documentElement;

    const footerPos = footerRef.current.getBoundingClientRect();
    const autoSpaceHeight = autoSpaceRef.current.getBoundingClientRect().height;

    const bodyHeight = Math.max(body.scrollHeight, body.offsetHeight);

    const rawBodyHeight = bodyHeight - autoSpaceHeight;

    const windowHeight = html.clientHeight;

    if (rawBodyHeight > windowHeight - 24) {
      if (autoSpaceHeight === 0) {
        return;
      } else {
        setAutoSpace(0);
      }
      return;
    }

    if (footerPos.bottom === windowHeight - 24) {
    } else if (footerPos.bottom < windowHeight - 24) {
      setAutoSpace(autoSpaceHeight + (windowHeight - 24 - footerPos.bottom));
    } else if (footerPos.bottom - autoSpaceHeight < windowHeight - 24) {
      setAutoSpace(autoSpaceHeight + (windowHeight - 24 - footerPos.bottom));
    }
  };

  const footerTimeout = () => {
    setTimeout(autoCdxFooter, 200);
  };

  useEffect(() => {
    window.addEventListener("resize", footerTimeout);
    document.body.addEventListener("click", footerTimeout);
    document.body.addEventListener("keypress", footerTimeout);
    footerTimeout();

    return () => {
      window.removeEventListener("resize", footerTimeout);
      document.body.removeEventListener("click", footerTimeout);
      document.body.removeEventListener("keypress", footerTimeout);
    };
  }, []);

  useEffect(() => {
    footerTimeout();
  }, [pathname]);

  const renderLinks = () =>
    links.map((link) => (
      <SuiBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <SuiTypography variant="button" fontWeight="regular" color="text">
            {t(link.name)}
          </SuiTypography>
        </Link>
      </SuiBox>
    ));

  return (
    <>
      <SuiBox ref={autoSpaceRef} sx={{ height: autoSpace }} />
      <SuiBox
        ref={footerRef}
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={1}
        pt={5}
      >
        <SuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          &copy; {new Date().getFullYear()},
          <Link href={company.href} target="_blank">
            <SuiTypography variant="button" fontWeight="medium">
              &nbsp;{t(company.name)}&nbsp;
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular">
              {t("All right reserved.")}
            </SuiTypography>
          </Link>
        </SuiBox>
        <SuiBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {renderLinks()}
        </SuiBox>
      </SuiBox>
    </>
  );
};

// Setting default values for the props of CdxFooter
CdxFooter.defaultProps = {
  company: { href: `${BASE_URL}`, name: "Codex Lab Co., Ltd." },
  links: [
    { href: `${BASE_URL}/contactus`, name: "Contact us" },
    {
      href: PRIVACY_POLICY,
      name: "Privacy Policy",
    },
  ],
};

// Typechecking props for the CdxFooter
CdxFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default CdxFooter;
