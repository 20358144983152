import SuiBox from "components/sui/SuiBox";

import Card from "@mui/material/Card";
import CreateSheetForm from "./form";

const CreateSheetInitial = ({ product }) => {
  return (
    <>
      {product && (
        <SuiBox py={3}>
          <Card>
            <CreateSheetForm product={product} />
          </Card>
        </SuiBox>
      )}
    </>
  );
};

export default CreateSheetInitial;
