/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

const Item = forwardRef(({ color, icon, title, description, ...rest }, ref) => (
  <SuiBox {...rest} ref={ref} display="flex" alignItems="center">
    <SuiBox mx={2} py={0.5} lineHeight={1}>
      <SuiTypography display="block" variant="button" fontWeight="medium">
        {title}
      </SuiTypography>
      {description && (
        <SuiTypography variant="button" fontWeight="regular" color="text">
          {description}
        </SuiTypography>
      )}
    </SuiBox>
  </SuiBox>
));

// Setting default values for the props of Item
Item.defaultProps = {
  color: "info",
};

// Typechecking props for the Item
Item.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default Item;
