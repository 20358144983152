import { connect } from "react-redux";

import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import CdxSnackbar from "components/cdx/CdxSnackbars/CdxSnackbar";

const CdxSnackbars = ({ snackBars }) => {
  return (
    <>
      {snackBars.length > 0 && (
        <SuiBox
          sx={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
          }}
        >
          <SuiBox p={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              spacing={2}
            >
              {snackBars.map((snackBar) => {
                return (
                  <Grid item key={snackBar.uuid}>
                    <CdxSnackbar
                      color={snackBar.color}
                      icon={snackBar.icon}
                      title={snackBar.title}
                      content={snackBar.content}
                      timestamp={snackBar.timestamp}
                      timeout={snackBar.timeout}
                      uuid={snackBar.uuid}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </SuiBox>
        </SuiBox>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    snackBars: state.app.snackBars,
  };
};

export default connect(mapStateToProps)(CdxSnackbars);
