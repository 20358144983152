import CryptoJS from "crypto-js";
import hashkey from "./hashkey";

export default function encrypt(data2Encrypt, encryptKey = hashkey()) {
  let string2Encrypt = "";
  if (typeof data2Encrypt === "string") {
    string2Encrypt = data2Encrypt;
  } else if (typeof data2Encrypt === "object") {
    string2Encrypt = JSON.stringify(data2Encrypt);
  }

  const keySize = 256;
  const ivSize = 128;
  const iterations = 100;

  const salt = CryptoJS.lib.WordArray.random(128 / 8);

  const key = CryptoJS.PBKDF2(encryptKey, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  const iv = CryptoJS.lib.WordArray.random(ivSize / 8);

  const encrypted = CryptoJS.AES.encrypt(string2Encrypt, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const transitmessage = salt.toString() + iv.toString() + encrypted.toString();

  return transitmessage;
}
