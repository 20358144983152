import CryptoJS from "crypto-js";
import decrypt from "./decrypt";
import hashkey from "./hashkey";

export default function decryptToObject(
  transitmessage,
  decryptKey = hashkey()
) {
  const decrypted = decrypt(transitmessage, decryptKey);
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(decryptedText);
  } catch {
    return decryptedText;
  }
}
